<template>
  <vs-card>
    <div slot="header">
      <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
        <vs-col vs-w="6">
          <h5 class="pt-2">Create Supplier</h5>
        </vs-col>
        <vs-col vs-w="6">
          <ul style="display:block;text-align:right;">
            <li style="display:inline-block;margin-right:5px;">
              <vs-button style="padding:5px 15px;" @click="onSave()">Save
              </vs-button>

            </li>
            <li style="display:inline-block">
              <vs-button style="padding:4px 15px;" @click="cancelCreate()" type="border">Cancel
              </vs-button>
            </li>
          </ul>
        </vs-col>

      </vs-row>
    </div>
    <div class="px-5">
      <SupplierDetails @createOrUpdateSupplier="createOrUpdateSupplier" :supplierId="supplierId" :onSave="save" :isCreate="true" />
    </div>
  </vs-card>
</template>

<script>
import SupplierDetails from "../../../components/WebStore/suppliers/SupplierDetailsV2";
import { mapActions } from 'vuex';

export default {
  name: 'Add Supplier',
  components: {
    SupplierDetails
  },
  data: () => ({
    save: false
  }),
  methods: {
    ...mapActions("supplier", ["addSupplier"]),
    createOrUpdateSupplier(data) {
      this.$vs.loading();
      let self = this;
      this.addSupplier(data)
        .then(res => {
          self.$vs.notify({
            title: "Supplier Add",
            text: "Supplier added successfully",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push({ name: 'superAdminStoreSuppliers' })
        })
        .catch(err => {
          self.$vs.notify({
            title: "Failed to create supplier",
            text: err.data.message,
            color: "danger",
          });
        });
    },
    cancelCreate() {
      this.$router.push({ name: "superAdminStoreSuppliers" });
    },
    onSave() {
      this.save = true;
      setTimeout(() => {
        this.save = false;
      }, 1000)
    }
  }
};
</script>
